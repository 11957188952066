import React from 'react';
import { BtnPrimary } from '~components/buttons/buttons.component';

import styles from './page-footer-links.module.scss';

const PageFooterLinks = ({ links }) => {
  if (!links || !links.length) {
    return null;
  }

  return (
    <div className={styles.pageFooterLinks}>
      {
        links.map((link, i) => {
          return (
            <BtnPrimary key={i} className={styles.btn} path={link.url}>
              {link.title}
            </BtnPrimary>
          );
        })
      }
    </div>
  );
};

export default PageFooterLinks;
