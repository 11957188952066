import React from 'react';
import { graphql } from 'gatsby';
import PageWrapper from '~components/page-wrapper/page-wrapper.component';
import SEO from '~components/seo';
import FeaturedCardList from '~components/featured-card-list/featured-card-list.component';
import ContentModules from '~components/content-modules/content-modules.component';
import PageFooterLinks from '~components/page-footer-links/page-footer-links.component';

import '~styles/pages/_content-page.scss';

const Page = ({ data }) => {
  const {
    title,
    description,
    headline,
    featuredCards,
    contentModules,
    footerLinks,
    references
  } = data.contentfulPage;

  const renderSeo = () => {
    if (!description) {
      return <SEO title={title} />;
    }
    return (
      <SEO
        title={title}
        description={description.description}
      />
    );
  };

  const renderContent = () => (
    <div className="content">
      <FeaturedCardList cards={featuredCards} />
      <ContentModules contentModules={contentModules} />
      <PageFooterLinks links={footerLinks} />
    </div>
  );

  return (
    <PageWrapper
      headline={headline.headline.headline}
      references={references}
      showIsi={true}
    >
      {renderSeo()}
      {renderContent()}
    </PageWrapper>
  );
}

export default Page;

export const query = graphql`
  query page($contentful_id: String) {
    contentfulPage(contentful_id: {eq: $contentful_id}) {
      contentful_id
      title
      description {
        description
      }
      slug
      headline {
        headline {
          headline
        }
      }
      featuredCards {
        title
        body {
          body
        }
        linkText
        link
        bynderImage {
          description
          name
          src
        }
      }
      contentModules {
        ... on Node {
          ... on ContentfulPageCopy {
            copy {
              copy
            }
          }
          ... on ContentfulPageCaption {
            copy {
              copy
            }
          }
          ... on ContentfulPageContentColor {
            copy {
              copy
            }
          }
          ... on ContentfulPageCustomTable {
            chartTitle {
              chartTitle
            }
            body {
              body
            }
            table
            callout {
              callout
            }
            footnote {
              footnote
            }
            sidebar {
              sidebar
            }
          }
          ... on ContentfulPageCallout {
            body {
              body
            }
            icon {
              name
              src
              original
            }
            mainImage {
              name
              src
              original
            }
            mainImageMobile {
              name
              src
              original
            }
          }
          ... on ContentfulPageChart {
            chartTitle {
              chartTitle
            }
            bynderImage {
              description
              name
              src
              original
            }
            callout {
              callout
            }
          }
          ... on ContentfulPageLearnMoreLink {
            title
            url
          }
          ... on ContentfulPageLeadGenForm {
            formtitle
            copy {
              copy
            }
            bynderHeaderImage {
              name
              src
              original
            }
            ssAccount
            ssFormId
            ssFormDomain
          }
        }
      }
      footerLinks {
        title
        url
      }
      references {
        references
      }
    }
  }
`
