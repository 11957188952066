import React from 'react';
import RenderMarkdown from '~components/RenderMarkdown';

import styles from './page-caption.module.scss';

const PageCaption = ({ text }) => {
  return (
    <div className={styles.pageCaption}>
      <RenderMarkdown text={text} />
    </div>
  );
};

export default PageCaption;
