import React from 'react';
import { Link } from 'gatsby';
import RenderMarkdown from '~components/RenderMarkdown';

import styles from './page-learn-more-link.module.scss';

const LearnMoreLink = ({title, url}) => {
  return (
    <div className={styles.learnMoreLink}>
      <Link to={`/${url}`}>
        <RenderMarkdown
          text={title}
          paragraph="span"
        />
      </Link>
    </div>
  );
}

export default LearnMoreLink;
