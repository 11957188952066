import React from 'react';
import RenderMarkdown from '~components/RenderMarkdown';
import PageCallout from '~components/page-callout/page-callout.component';
import PageCaption from '~components/page-caption/page-caption.component';
import PageContentColor from '~components/page-content-color/page-content-color.component';
import PageChart from '~components/page-chart/page-chart.component';
import PageCustomTable from '~components/page-custom-table/page-custom-table.component';
import PageLearnMoreLink from '~components/page-learn-more-link/page-learn-more-link.component';
import PageLeadGenForm from '~components/page-lead-gen-form/leadgenform.component';

const ContentModules = ({contentModules}) => {
  if (!contentModules || !Array.isArray(contentModules)) {
    return null;
  }

  const renderContentModules = () => {
    return contentModules.map((module, i) => {
      const typename = module.__typename
        ? module.__typename
        : module.sys
          ? module.sys.contentType.sys.id
          : false;

      if (!typename) {
        return null;
      }

      switch (typename) {
        // Page Copy
        case "pageCopy":
        case "ContentfulPageCopy":
          const pageCopy = typename === 'pageCopy' ? module.fields.copy : module.copy.copy;
          return (
            <RenderMarkdown
              key={i}
              text={pageCopy}
            />
          );

        // Page Caption.
        case "pageCaption":
        case "ContentfulPageCaption":
          const pageCaption = typename === 'pageCaption' ? module.fields.copy : module.copy.copy;
          return (
            <PageCaption
              key={i}
              text={pageCaption}
            />
          );

        // Page Content Color.
        case "pageContentColor":
        case "ContentfulPageContentColor":
          const pageContentColor = typename === 'pageContentColor' ? module.fields.copy : module.copy.copy;
          return (
            <PageContentColor
              key={i}
              text={pageContentColor}
            />
          );

        // Page Chart.
        case "pageChart":
        case "ContentfulPageChart":
          let chartTitle,
              chartImage,
              chartCallout;
          if (typename === 'pageChart') {
            chartTitle = module.fields.chartTitle;
            chartImage = module.fields.bynderImage;
            chartCallout = module.fields.callout;
          }
          else {
            chartTitle = module.chartTitle?.chartTitle;
            chartImage = module.bynderImage;
            chartCallout = module.callout?.callout;
          }
          return (
            <PageChart
              key={i}
              title={chartTitle}
              image={chartImage}
              callout={chartCallout}
            />
          );

        // Page Callout.
        case "pageCallout":
        case "ContentfulPageCallout":
          let text,
              icon,
              image,
              imageMobile;
          if (typename === 'pageCallout') {
            text = module.fields.body;
            icon = module.fields.icon;
            image = module.fields.mainImage;
            imageMobile = module.fields.mainImageMobile;
          }
          else {
            text = module.body.body;
            icon = module.icon;
            image = module.mainImage;
            imageMobile = module.mainImageMobile;
          }
          return (
            <PageCallout
              key={i}
              text={text}
              icon={icon}
              image={image}
              imageMobile={imageMobile}
            />
          );

        // Page custom table.
        case "pageCustomTable":
        case "ContentfulPageCustomTable":
          let title,
              body,
              table,
              callout,
              footnote,
              sidebar;
          if (typename === 'pageCustomTable') {
            title = module.fields.chartTitle;
            body = module.fields.body;
            table = module.fields.table;
            callout = module.fields.callout;
            footnote = module.fields.footnote;
            sidebar = module.fields.sidebar;
          }
          else {
            title = module.chartTitle?.chartTitle;
            body = module.body?.body;
            table = module.table;
            callout = module.callout?.callout;
            footnote = module.footnote?.footnote;
            sidebar = module.sidebar?.sidebar;
          }
          return (
            <PageCustomTable
              key={i}
              title={title}
              body={body}
              table={table}
              callout={callout}
              footnote={footnote}
              sidebar={sidebar}
            />
          );

        case "pageLearnMoreLink":
        case "ContentfulPageLearnMoreLink":
          const lMLProps = typename === 'pageLearnMoreLink' ? module.fields : module;
          return (
            <PageLearnMoreLink
              key={i}
              {...lMLProps}
            />
          );

        case "pageLeadGenForm":
        case "ContentfulPageLeadGenForm":
          const pageLeadGenFormTitle = typename === 'pageLeadGenForm' ? module.fields.formtitle : module.formtitle;
          const pageLeadGenFormCopy = typename === 'pageLeadGenForm' ? module.fields.copy : module.copy.copy;
          const pageLeadGenFormImage = typename === 'pageLeadGenForm' ? module.fields.bynderHeaderImage : module.bynderHeaderImage;
          const pLGFAccount = typename === 'pageLeadGenForm' ? module.fields.ssAccount : module.ssAccount;
          const pLGFId = typename === 'pageLeadGenForm' ? module.fields.ssFormId : module.ssFormId;
          const pLGFDomain = typename === 'pageLeadGenForm' ? module.fields.ssFormDomain : module.ssFormDomain;
          return (
            <PageLeadGenForm
              key={i}
              title={pageLeadGenFormTitle}
              text={pageLeadGenFormCopy}
              image={pageLeadGenFormImage}
              plgfaccount={pLGFAccount}
              plgfid={pLGFId}
              plgfdomain={pLGFDomain}
            />
          );

        default:
          return null;
      }
    });
  }

  return (
    <>
      {renderContentModules()}
    </>
  );
}

export default ContentModules;
