import React from 'react';
import RenderMarkdown from '~components/RenderMarkdown';

import styles from './page-content-color.module.scss';

const PageContentColor = ({ text }) => {
  return (
    <div className={styles.pageContentColor}>
      <RenderMarkdown text={text} />
    </div>
  );
};

export default PageContentColor;
