import React from 'react';
import RenderMarkdown from '~components/RenderMarkdown';

import styles from './page-callout.module.scss';

const PageCallout = ({text, icon, image, imageMobile}) => {
  const renderImg = (imgObj) => {
    if (imgObj.length) {
      const {
        original,
        description,
        name,
      } = imgObj[0];
      return (
        <img src={original} alt={description ? description : name} />
      );
    }
  };

  return (
    <div className={styles.pageCallout}>
      <div className={styles.wrapper}>
        {icon &&
          <div className={styles.icon}>
            {renderImg(icon)}
          </div>
        }
        <div>
          <RenderMarkdown text={text} />
        </div>
      </div>
      {image &&
        <div className={`${styles.mainImage} hidden-mobile`}>
          {renderImg(image)}
        </div>
      }
      {imageMobile &&
        <div className="hidden-desktop">
          {renderImg(imageMobile)}
        </div>
      }
    </div>
  );
}

export default PageCallout;
