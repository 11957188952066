import React from 'react';
import RenderMarkdown from '~components/RenderMarkdown';

import styles from './page-chart.module.scss';

const PageChart = ({ title, image, callout }) => {
  let imageObj;
  if (image && image.length) {
    imageObj = {
      src: image[0].original,
      alt: image[0].description ? image[0].description : image[0].name,
    };
  }

  const renderImg = () => {
    if (!imageObj) {
      return null;
    }
    return (
      <div className={styles.chartImage}>
        <img
          src={imageObj.src}
          alt={imageObj.alt}
        />
      </div>
    );
  }

  return (
    <div className={styles.chart}>
      {title &&
        <h4>
          <RenderMarkdown text={title} paragraph="span" />
        </h4>
      }
      <div className={styles.wrapper}>
        {renderImg()}
        {callout &&
          <div className={styles.sidebar}>
            <div className={styles.chartCallout}>
              <RenderMarkdown text={callout} />
            </div>
          </div>
        }
      </div>
    </div>
  );
}

export default PageChart;
