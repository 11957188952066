import RenderMarkdown from '~components/RenderMarkdown';
import React from 'react';

import styles from './page-custom-table.module.scss';

const tableData = {
  'PatientDemographics': {
    'type': 'regular-table',
    'headers': [
      'Characteristics',
      'Bronchoscopy  \nASA-PS I-III  \n(N=431)',
      'Colonoscopy  \nASA-PS I-III  \n(N=458)',
      'Colonoscopy  \nASA-PS III-IV  \n(N=77)',
    ],
    'rows': [
      {
        class: 'main-row',
        items: [
          'Mean age, years (range)',
          '62.3 (22-95)',
          '54.9 (19-92)',
          '62.5 (42-84)',
        ],
      },
      {
        class: 'sub-row',
        items: [
          'Age <65, %',
          '51.5',
          '86.2',
          '59.7',
        ],
      },
      {
        class: 'sub-row',
        items: [
          'Age >65, %',
          '48.5',
          '13.8',
          '40.3',
        ],
      },
      {
        class: 'main-row',
        items: [
          'Sex, male %',
          '45.9',
          '47.6',
          '55.8',
        ],
      },
      {
        class: 'main-row',
        items: [
          'Mean weight, lb (range)',
          '177.7 (70.4-402.6)',
          '182.8 (88.0-316.8)',
          '198.9 (125.4-374.0)',
        ],
      },
      {
        class: 'main-row',
        items: [
          'Mean BMI, kg/m^2^ (range)',
          '28.3 (14-45)',
          '29.0 (17-40)',
          '30.8 (22-55)',
        ],
      },
      {
        class: 'main-row',
        items: [
          'ASA-PS, % (n)',
          '',
          '',
          '',
        ],
      },
      {
        class: 'sub-row',
        items: [
          'I',
          '3.5 (15)',
          '31.2 (143)',
          '0',
        ],
      },
      {
        class: 'sub-row',
        items: [
          'II',
          '58.9 (254)',
          '62.2 (285)',
          '0',
        ],
      },
      {
        class: 'sub-row',
        items: [
          'III',
          '37.6 (162)',
          '6.6 (30)',
          '51.9 (40)',
        ],
      },
      {
        class: 'sub-row',
        items: [
          'IV',
          '0',
          '0',
          '48.1 (37)',
        ],
      },
    ],
  },
  'ProcedureCompletionRates': {
    'type': 'colored-table',
    'headers': [
      '% (n/N)',
      'Byfavo',
      'Placebo With  \nMidazolam Rescue',
      'Open-Label  \nMidazolam',
    ],
    'rows': [
      {
        class: 'main-row',
        items: [
          'Pooled',
          '97.3% (623/640)',
          '97.1% (135/139)',
          '96.1% (199/207)',
        ],
      },
    ],
  },
  'IncidenceLowestScore': {
    'type': 'colored-table',
    'headers': [
      '',
      'Byfavo  \n(N=630)',
      'Placebo With  \nMidazolam Rescue  \n(N=135)',
      'Open-Label  \nMidazolam  \n(N=201)',
    ],
    'rows': [
      {
        class: 'main-row',
        items: [
          'MOAA/S = 2-4 target sedation level, % (n)',
          '81.5% (514)',
          '88.1% (119)',
          '91.0% (183)',
        ],
      },
      {
        class: 'main-row',
        items: [
          'MOAA/S = 0-1, % (n)',
          '18.3% (115)',
          '11.9% (16)',
          '8.5% (17)',
        ],
      },
      {
        class: 'main-row',
        items: [
          'Median total time with MOAA/S at 0-1 (range), minutes',
          '4.0 (0.5-45.9)',
          '6.0 (1.0-32.4)',
          '3.0 (1.0-51.0)',
        ],
      },
    ],
  },
  'AdverseReactionsAnyTreatmentGroup': {
    'type': 'colored-table',
    'headers': [
      'Adverse Reactions  \n% (n)',
      'Byfavo  \n(N=630)',
      'Placebo With Midazolam  \n(N=135)',
      'Open-Label Midazolam  \n(N=201)',
    ],
    'rows': [
      {
        class: 'main-row',
        items: [
          'Hypotension*',
          '37.0 (233)',
          '47.4 (64)',
          '51.2 (103)',
        ],
      },
      {
        class: 'main-row',
        items: [
          'Hypertension^†^',
          '25.1 (158)',
          '23.7 (32)',
          '24.9 (50)',
        ],
      },
      {
        class: 'main-row',
        items: [
          'Diastolic hypertension^†^',
          '17.3 (109)',
          '15.6 (21)',
          '12.4 (25)',
        ],
      },
      {
        class: 'main-row',
        items: [
          'Systolic hypertension^†^',
          '13.5 (85)',
          '13.3 (18)',
          '11.4 (23)',
        ],
      },
      {
        class: 'main-row',
        items: [
          'Hypoxia^‡^',
          '11.0 (69)',
          '10.4 (14)',
          '7.0 (14)',
        ],
      },
      {
        class: 'main-row',
        items: [
          'Diastolic hypotension*',
          '10.3 (65)',
          '16.3 (22)',
          '12.4 (25)',
        ],
      },
      {
        class: 'main-row',
        items: [
          'Bradycardia',
          '7.1 (45)',
          '8.9 (12)',
          '11.9 (24)',
        ],
      },
      {
        class: 'main-row',
        items: [
          'Respiratory  \nrate increased',
          '6.8 (43)',
          '4.4 (6)',
          '5.0 (10)',
        ],
      },
      {
        class: 'main-row',
        items: [
          'Tachycardia',
          '4.3 (27)',
          '6.7 (9)',
          '6.5 (13)',
        ],
      },
      {
        class: 'main-row',
        items: [
          'Nausea',
          '2.7 (17)',
          '4.4 (6)',
          '2.0 (4)',
        ],
      },
      {
        class: 'main-row',
        items: [
          'Headache',
          '2.1 (13)',
          '0 (0)',
          '3.0 (6)',
        ],
      },
      {
        class: 'main-row',
        items: [
          'Tachypnea',
          '1.3 (8)',
          '4.4 (6)',
          '2.0 (4)',
        ],
      },
      {
        class: 'main-row',
        items: [
          'Respiratory acidosis',
          '1.0 (6)',
          '1.5 (2)',
          '4.0 (8)',
        ],
      },
      {
        class: 'main-row',
        items: [
          'Respiratory  \nrate decreased',
          '1.7 (11)',
          '2.2 (3)',
          '3.5 (7)',
        ],
      },
      {
        class: 'main-row',
        items: [
          'Vomiting',
          '1.4 (9)',
          '2.2 (3)',
          '1.0 (2)',
        ],
      },
    ],
  },
  'AdverseReactionsColonoscopyPatients': {
    'type': 'colored-table',
    'headers': [
      'Adverse Reactions  \n% (n)',
      'Byfavo  \n(N=31)',
      'Placebo With  \nMidazolam Rescue  \n(N=16)',
      'Open-Label  \nMidazolam  \n(N=30)',
    ],
    'rows': [
      {
        class: 'main-row',
        items: [
          'Hypotension*',
          '58 (18)',
          '69 (11)',
          '57 (17)',
        ],
      },
      {
        class: 'main-row',
        items: [
          'Hypertension^†^',
          '42 (13)',
          '38 (6)',
          '43 (13)',
        ],
      },
      {
        class: 'main-row',
        items: [
          'Respiratory acidosis',
          '19 (6)',
          '13 (2)',
          '27 (8)',
        ],
      },
      {
        class: 'main-row',
        items: [
          'Diastolic hypertension^†^',
          '10 (3)',
          '0 (0)',
          '0 (0)',
        ],
      },
      {
        class: 'main-row',
        items: [
          'Systolic hypertension^†^',
          '6 (2)',
          '0 (0)',
          '0 (0)',
        ],
      },
      {
        class: 'main-row',
        items: [
          'Bradycardia',
          '3 (1)',
          '6 (1)',
          '13 (4)',
        ],
      },
      {
        class: 'main-row',
        items: [
          'Respiratory  \nrate decreased',
          '3 (1)',
          '6 (1)',
          '7 (2)',
        ],
      },
      {
        class: 'main-row',
        items: [
          'Diastolic hypotension*',
          '3 (1)',
          '6 (1)',
          '0 (0)',
        ],
      },
      {
        class: 'main-row',
        items: [
          'Blood pressure diastolic increased',
          '3 (1)',
          '0 (0)',
          '0 (0)',
        ],
      },
      {
        class: 'main-row',
        items: [
          'Blood pressure increased',
          '3 (1)',
          '0 (0)',
          '0 (0)',
        ],
      },
      {
        class: 'main-row',
        items: [
          'Blood pressure  \nsystolic increased',
          '3 (1)',
          '0 (0)',
          '0 (0)',
        ],
      },
      {
        class: 'main-row',
        items: [
          'Upper respiratory  \ntract infection',
          '3 (1)',
          '0 (0)',
          '0 (0)',
        ],
      },
    ],
  },
  'ProceduralSedation': {
    'type': 'simple-table',
    'headers': [
      'For Adult Patients',
      'For ASA-PS III-IV Adult Patients',
    ],
    'rows': [
      {
        class: 'main-row',
        items: [
          'Induction',
        ],
      },
      {
        class: 'sub-row',
        items: [
          'Administer 5 mg intravenously over a 1-minute time period.',
          'Administer 2.5 mg to 5 mg intravenously over 1 minute based on the general condition of the patient.',
        ],
      },
      {
        class: 'main-row',
        items: [
          'Maintenance (as needed)  \n\nAt least 2 minutes must elapse prior to administration of any supplemental dose and assessment of level of sedation',
        ],
      },
      {
        class: 'sub-row',
        items: [
          'Administer 2.5 mg intravenously over 15 seconds.',
          'Administer 1.25 mg to 2.5 mg intravenously over 15 seconds.',
        ],
      },
    ],
  },
};

const renderTable = (table) => {
  const headerRowClasses = [
    styles.row,
    styles.tableHeader,
  ].join(' ');
  const bodyRowClasses = [
    styles.row,
    styles.tableBody,
  ];

  return (
    <div className={[styles.tableWrapper, tableData[table].type].join(' ')}>
      {tableData[table].headers &&
        <div className={headerRowClasses}>
          {tableData[table].headers.map((header, i) => (
            <div key={i}>
              <RenderMarkdown text={header} paragraph="span" />
            </div>
          ))}
        </div>
      }
      {tableData[table].rows && tableData[table].rows.map((row, i) => {
        return (
          <div key={i} className={[...bodyRowClasses, row.class].join(' ')}>
            {row.items.map((value, k) => (
              <div key={k}>
                <RenderMarkdown text={value} paragraph="span" />
              </div>
            ))}
          </div>
        );
      })}
    </div>
  );
};

const PageCustomTable = ({ title, body, table, footnote, callout, sidebar }) => {
  return (
    <div className={styles.customTable}>
      {title &&
        <h4>
          <RenderMarkdown text={title} paragraph="span" />
        </h4>
      }
      {body &&
        <div className={styles.body}>
          <RenderMarkdown text={body} />
        </div>
      }
      <div className={styles.wrapper}>
        <div>
          {tableData[table] && renderTable(table)}
          {footnote &&
            <div className={styles.footnote}>
              <RenderMarkdown text={footnote} />
            </div>
          }
        </div>
        {(callout || sidebar) &&
          <div className={styles.sidebar}>
            {callout &&
              <div className={styles.callout}>
                <RenderMarkdown text={callout} />
              </div>
            }
            {sidebar && <RenderMarkdown text={sidebar} />}
          </div>
        }
      </div>
    </div>
  );
};

export default PageCustomTable;
